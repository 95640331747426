import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
    constructor(type) {
        super()
        if (type === 'form') {
            this.merchantName = '' // 商户名称
            this.withdrawalStatus = '' // 提现状态
            this.withdrawalNumber = '' // 提现单号
            this.withdrawalStartTime = ''
            this.withdrawalEndTime = ''
            this.merchantAccountId = ''
        }
    }
}
export default searchFrom
