<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      title="详情"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="600px"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="20" class="detail-content">
            <el-form-item label="提现时间:">
              <span>{{ form.withdrawalTime }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="提现单号:">
              <span>{{ form.withdrawalNumber }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="提现金额:">
              <span class="price">{{ form.withdrawalAmount }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="提现手续费:">
              <span>{{ form.withdrawalServiceCharge }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="到账银行卡信息:"></el-form-item>
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="开户行名称:"
              ><span> {{ form.bankName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="账户名:">
              <span>{{ form.accountName }}</span></el-form-item
            >
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="银行账号:"
              ><span>{{ form.accountCode }}</span></el-form-item
            >
          </el-col>
          <el-col :span="20" class="detail-content">
            <el-form-item label="提现状态:"
              >{{ form.withdrawalStatusDesc }}
            </el-form-item>
          </el-col>
          <el-col
            :span="20"
            class="detail-content"
            v-if="form.withdrawalStatus === 2"
          >
            <el-form-item label="不通过原因:"
              ><span>{{ form.rejectReasons }}</span></el-form-item
            >
          </el-col>
          <el-row v-if="form.withdrawalStatus === 4">
            <el-col :span="20" class="detail-content">
              <el-form-item label="打款时间:"
                ><span>{{ form.paymentTime }}</span></el-form-item
              >
            </el-col>
            <el-col :span="20" class="detail-content">
              <el-form-item label="打款金额:"
                ><span>{{ form.paymentAmount }}</span></el-form-item
              >
            </el-col>
            <el-col :span="20">
              <el-form-item label="打款凭证:">
                <div
                  style="display: flex;"
                >
                  <el-popover
                    v-for="(item, index) in form.paymentVoucherUrl"
                    :key="index"
                    :placement="index === 0 ? 'left' : 'right'"
                    trigger="hover"
                  >
                    <img :src="item || ''" height="400" />
                    <img
                      style="padding: 0px 3px"
                      slot="reference"
                      :src="item || ''"
                      height="65"
                      width="65"
                    />
                  </el-popover>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="20" class="detail-content">
              <el-form-item label="上传凭证时间:"
                ><span>{{ form.uploadVoucherTime }}</span></el-form-item
              >
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import DetailDialogClass from "./detailDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  components: {},
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new DetailDialogClass("form"), // 实例化一个表单的变量
      rules: new DetailDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      id: "",
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  activated() {},
  methods: {
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init();
      this.$emit("closeDialog", false);
    },
    ...mapMutations(["setVenueId", "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new DetailDialogClass("form");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    getDetail(id) {
      this.$http.get(`${apis.getDetail}?id=${id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = { ...res.data.data };
        }
      });
    },
    sureEvent() {
      this.closeDialog();
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.id = data.id;
    },
    openDialog() {
      this.getDetail(this.id);
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
.line {
  width: 100%;
  height: 0;
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
.detail-content {
  height: 45px;
  .price {
    color: red;
  }
}
.detail-img-box {
  display: flex;
  .img-one {
    width: 80px;
    height: 80px;
    // background: red;
    margin-left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .img-one:nth-child(1) {
    margin-left: 0;
  }
}
</style>
