<template>
  <div class="mustSeeSpot">
    <div class="search-container search-container-type">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="100px">
            <el-row>
              <el-col :span="8">
                <el-form-item label="提现时间" label-width="85px">
                  <el-date-picker
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    v-model="rang"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="withdrawalNumber" label="提现单号：">
                  <el-input
                    clearable
                    v-model="form.withdrawalNumber"
                    placeholder="请输入提现单号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="withdrawalStatus" label="提现状态：">
                  <el-select
                    v-model="form.withdrawalStatus"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in statusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4" style="text-align: right">
          <el-button @click="cleanForm" class="clean-button">{{
            $t("reset")
          }}</el-button>
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="withdrawalTime"
          min-width="168"
          align="center"
          label="提现时间"
        >
        </el-table-column>
        <el-table-column
          prop="withdrawalNumber"
          min-width="100"
          align="center"
          label="提现单号"
        >
        </el-table-column>
        <el-table-column
          prop="withdrawalAmount"
          min-width="168"
          align="center"
          label="提现金额"
        >
        </el-table-column>
        <el-table-column
          prop="withdrawalServiceCharge"
          min-width="168"
          align="center"
          label="提现手续费"
        >
        </el-table-column>
        <el-table-column
          prop="withdrawalStatusDesc"
          min-width="168"
          align="center"
          label="提现状态"
        >
        </el-table-column>
        <el-table-column
          prop="paymentTime"
          min-width="168"
          align="center"
          label="打款时间"
        >
        </el-table-column>
        <el-table-column
          prop="paymentAmount"
          min-width="168"
          align="center"
          label="打款金额"
        >
        </el-table-column>
        <el-table-column
          prop="paymentVoucherUrl"
          min-width="168"
          align="center"
          label="打款凭证"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.paymentVoucherUrl"
              style="display: flex; justify-content: center"
            >
              <el-popover
                v-for="(item, index) in scope.row.paymentVoucherUrl"
                :key="index"
                :placement="index === 0 ? 'left' : 'right'"
                trigger="hover"
              >
                <!--trigger属性值：hover、click、focus 和 manual-->
                <img :src="item || ''" height="400" />
                <img
                  style="padding: 0px 3px"
                  slot="reference"
                  :src="item || ''"
                  height="65"
                  width="65"
                />
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="rejectReasons"
          min-width="168"
          align="center"
          label="不通过原因"
        >
        </el-table-column>
        <el-table-column
          prop="collectCount"
          align="center"
          width="300"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              class="default-button"
              @click="changeDetailDialog(true, true, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-container">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
    <!-- 详情弹窗 -->
    <detailDialog
      ref="detailDialog"
      :Language="Language"
      :show="detailDialog.visible"
      @closeDialog="changeDetailDialog"
    ></detailDialog>
  </div>
</template>
<script>
import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import detailDialog from "./dialog/detailDialog.vue";

export default {
  mixins: [mixin],
  components: { detailDialog },
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      tableData: [],
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      rang: [],
      detailDialog: {
        visible: false,
      },
      total: 0,
      statusList: [
        {
          value: "1",
          label: "提现申请中",
        },
        {
          value: "2",
          label: "提现不通过",
        },
        {
          value: "3",
          label: "待打款",
        },
        {
          value: "4",
          label: "已提现",
        },
      ],
      value: "",
    };
  },
  computed: {
    ...mapState([
      "Language",
      "DROPDOWNBOX",
      "TOKEN",
      "venueId",
      "merchantAccountDetailInfoDTO",
    ]),
  },
  activated() {},
  methods: {
    // 清空按钮
    cleanForm() {
      this.$refs.form.resetFields();
      //清空
      this.form.beName = "";
      this.form.entityTypeId = "";
      this.form.status = "";
      this.rang = [];
      this.searchEvent();
    },
    // 查询
    // 获取主体列表数据
    searchData() {
      if (this.rang) {
        if (this.rang.length > 0) {
          this.form.withdrawalStartTime = this.rang[0];
          this.form.withdrawalEndTime = this.rang[1];
        }
      } else {
        this.form.withdrawalStartTime = "";
        this.form.withdrawalEndTime = "";
      }
      if (this.merchantAccountDetailInfoDTO.merchantName) {
        this.form.merchantName = this.merchantAccountDetailInfoDTO.merchantName;
      } else {
        this.form.merchantName = "";
      }
      if (this.merchantAccountDetailInfoDTO.merchantId) {
        this.form.merchantAccountId = this.merchantAccountDetailInfoDTO.merchantId;
      } else {
        this.form.merchantAccountId = "";
      }
      const formData = { ...this.form };
      this.$http.post(apis.merchantWithdrawal, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
          //   this.total = res.data.total;
        }
      });
    },
    // 详情
    changeDetailDialog(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$nextTick(() => {
            this.$refs["detailDialog"].getDataQuery(data);
          });
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.detailDialog.visible = dialogStatus;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.less";
.mustSeeSpot {
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .default-button {
    border-radius: 2px;
  }
  .add-button {
    margin: 10px;
  }
  .main-title {
    margin: 10px;
  }
  .num-label {
    margin-right: 10px;
  }
  .page-container {
    padding: 17px !important;
    margin-top: 0px !important;
  }
}
</style>
