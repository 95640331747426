/* eslint-disable */
class searchFrom {
    constructor(type) {
        if (type === 'form') {
            // this.entityTypeId = ''
            // this.telephone = ''
        } else if (type === 'rule') {
        }
    }
}
export default searchFrom